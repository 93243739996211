<template>
  <main>
    <div class="bg-white pb-20 px-4 sm:px-6 lg:pb-28 lg:px-8">
      <div class="relative max-w-lg mx-auto lg:max-w-7xl">
        <div>
          <h1
            class="text-3xl mb-12 leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10"
          >
            What is CBDCOAs?
          </h1>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            When CBD Hexa entered the hemp/CBD oil space in 2016, we noticed a
            few things that stood out as major red flags. Customers were
            purchasing hemp and CBD oil products without any idea where the hemp
            was grown. The second biggest problem was the certificates of
            analysis (COAs): were they real? Were they stolen? Were they
            manipulated by the merchant? Finally, CBD merchants' websites were
            making many different claims as to CBD's effectiveness with respect
            to healing and curative abilities. In 2016, very little research had
            been completed and the leaders of the pack at that point were the
            Stanley Brothers. They developed a CBD strain that ultimately led to
            the name of their company, Charlotte's Web.
          </p>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            Our mission became clear: create a system that ensured the merchant
            and the bank were protected. The merchants needed protection from
            themselves and the FDA. The banks needed protection from the
            merchants, the FDA, the growers, the labs, the distributors, and
            others, including gas stations and convenience stores that were
            selling low-quality products with little-to-no CBD in the
            ingredients.
          </p>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            By 2018, the market was ready to explode. The 2018 Farm Bill Act was
            signed in December and a major processing company decided to bring
            on all the CBD and hemp merchants that they could handle.
            Auto-approval was almost guaranteed, with no COAs, underwriting,
            site surveys of retail locations, or products required. It was a
            free-for-all. Merchants were selling everything from kratom to
            synthetic CBD to spice and kush. By definition, hemp contains less
            than .3% THC and doesn't cause any psychoactive effects. In March of
            2019, this credit card processor announced that they would stop
            processing credit cards for hemp/CBD merchants by the end of May.
          </p>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            In early 2019, the CBD Hexa technical team was nearing a
            breakthrough in watermarking technology and anti-doctoring
            algorithms. With the assistance of the labs, if we were given first
            access to the lab reports, we could watermark them and send them
            directly to the banks and merchants, bypassing the possibility for
            merchant doctoring. In April of 2019, we finished the project and
            created a watermarking system that could prevent COA theft and
            document alteration. We also developed a tool that scanned the
            merchant's website as well as their social media profiles for
            potential claims regarding diseases, cures, pain relief, etc. At
            that point, CBD Hexa was born.
          </p>
          <p class="mt-3 text-xl leading-7 text-gray-500 sm:mt-4">
            So, check us out! Let us protect your lab, your product, your
            livelihood. We verify every COA that comes into our company, so that
            a lab doesn't have to suffer the embarrassment of having fraudulent
            COAs floating around or being tied to doctored COAs because they
            were unable to protect against forgery. This also inspires customer
            confidence in both your lab and the merchant's product by ensuring
            that the product they're purchasing contains the claimed ingredients
            and they're getting what they paid for.
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  metaInfo: {
    title: "What is CBDCOAs?",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "CBDCOAs is part of the CBDHexa program that enables CBD vendors to watermark their certificates of analysis (CoA) as well as provide customers a place to view verified CoA's before purchasing products."
      }
    ]
  }
};
</script>
